const en = {
  globals: {
    words: {
      total: 'Total',
      standard: 'Standard',
      detailed: 'Detailed',
      last7Days: 'Last 7 Days',
      last14Days: 'Last 14 Days',
      last30Days: 'Last 30 Days',
      last90Days: 'Last 90 Days',
      actualMonth: 'Actual Month',
      lastMonth: 'Last Month',
      last3Months: 'Last 3 Months',
      day: 'Day',
      days: 'Days',
    },
  },
  pages: {
    login: {
      labels: {
        username: 'Username',
        password: 'Password',
        totp: 'One-time code',
        autologin: 'Stay logged-in?',
        submit: {
          credentials: 'Login',
          totp: 'Confirm',
        },
      },
      links: {
        passwordReset: 'Password forgotten?',
        imprint: 'Imprint',
        privacy: 'Privacy',
      },
      totp: {
        description:
          'As an additional security measure, please enter the numeric code generated by your authenticator app.',
      },
      validation: {
        usernameRequired: 'Username is required.',
        passwordRequired: 'Password is required.',
        invalidCredentials: 'Username or password is wrong.',
        totpRequired: 'TOTP is required.',
        sessionStartFailed: 'There was an issue logging in. Please try again later.',
      },
    },
    project: {
      header: {
        projectAddress: 'Project Address',
        projectStatus: 'Project Status',
        billingType: 'Billing Type',
        responsibleProjectManager: 'Responsible Project Manager',
        startOfConstruction: 'Start of Construction',
        endOfConstruction: 'End of Construction',
        progress: 'Progress',
        //
        totalTime: 'Total Time',
        alreadyCompleted: 'Already Completed',
        unproductive: 'Unproductive',
        unproductiveTime: 'Unproductive Time',
        productiveTime: 'Productive Time',
        nonContractual: 'Non Contractual',
        nonContractualTime: 'Non Contractual Time',
        contractualTimeOriginalPlan: 'Contractual Time(Original Plan)',
        addendum: 'Addendum',
        deletedPositions: 'Deleted Positions',
        addedUnits: 'Added Units',
        reducedUnits: 'Reduced Units',
        differenceToOriginalCalculation: 'Difference to Original Calculation (Actual Plan)',
        currentOrderVolume: 'Current Order Volume',
        PAC: 'PAC',
        toBeDone: 'To be Done',
        differenceToActualPlan: 'Difference to Actual Plan',
        differenceToOriginalPlan: 'Difference to Original Plan',
        open: 'Open',
        //
        details: 'Details',
        PACTotal: 'Plan-Actual-Comparison (PAC)',
        last7Days: 'Last 7 Days',
        process: 'Process',
        //
        valuable: 'Valuable',
        nonBillable: 'Non Billable',
        nonValuable: 'Non Valuable',
        //
        travelTime: 'Travel Time',
        setupTime: 'Setup Time',
        otherUnproductiveTime: 'Other Unproductive Time',
        //
        totalOverview: 'Total Overview',
        times: 'Times',
        hours: 'Hours',
        factor: 'Factor',
        percentage: 'Proportion in %',
        originalOrderTime: 'Original order time',
        //
        h: 'h',
        //
        PACDetails: 'PAC Details',
      },
    },
    dashboard: {
      labels: {
        dailyPlanning: 'Daily Planning',
        news: 'News',
        timeRecording: 'Time Recording',
        dailyReports: 'Daily Reports',
        photoDocumentation: 'Photo Documentation',
        lastPhoto: 'last Photo',
        POBox: 'P.O. Box',
        tasks: 'Tasks',
        constructionSites: 'Construction Sites',
        nonContractualWork: 'Non Contractual Work',
        feedback: 'Feedback',
        quickLinks: 'Quick Links',
        vacations: 'Vacations',
      },
      vacations: {
        currentYearClaim: 'Claim',
        lastYearClaim: 'Last year claim',
        totalClaim: 'Total claim',
        taken: 'Taken',
        planned: 'Planned',
        requested: 'Requested',
        rest: 'Rest',
      },
    },
  },
};

export default en;
