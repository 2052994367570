import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '../../../enums/RequestStatus';

export type DashboardCard = {
  size: number;
  name: DashboardCardNames;
  configuration?: { [key: string]: any };
};

export type Dashboard = DashboardCard[];

interface DashboardState {
  dashboard: Dashboard | null;
  status: RequestStatus;
  error: string | null;
}

export enum DashboardCardNames {
  dailyPlanning = 'dailyPlanning',
  news = 'news',
  timeRecording = 'timeRecording',
  dailyReports = 'dailyReports',
  photoDocumentation = 'photoDocumentation',
  lastPhoto = 'lastPhoto',
  POBox = 'POBox',
  tasks = 'tasks',
  constructionSites = 'constructionSites',
  nonContractualWork = 'nonContractualWork',
  feedback = 'feedback',
  quickLinks = 'quickLinks',
  vacations = 'vacations',
}

const initialState: DashboardState = {
  dashboard: null,
  status: RequestStatus.IDLE,
  error: null,
};

export const getDashboardItems = createAsyncThunk('dashboard/items', async () => {
  // const response = await ApiService.get<Dashboard>('');
  return DASHBOARD;
  // return response.data;
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDashboardItems.pending, state => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(getDashboardItems.fulfilled, state => {
        state.status = RequestStatus.SUCCEEDED;
        // state.dashboard = action.payload;
        state.dashboard = DASHBOARD;
      })
      .addCase(getDashboardItems.rejected, (state, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default dashboardSlice.reducer;

const DASHBOARD: Dashboard = [
  {
    size: 2,
    name: DashboardCardNames.dailyPlanning,
  },
  {
    size: 2,
    name: DashboardCardNames.news,
  },
  {
    size: 2,
    name: DashboardCardNames.timeRecording,
  },
  {
    size: 2,
    name: DashboardCardNames.dailyReports,
  },
  {
    size: 1,
    name: DashboardCardNames.photoDocumentation,
    configuration: {
      time_span: 7,
      categorie: 4,
    },
  },
  {
    size: 1,
    name: DashboardCardNames.lastPhoto,
  },
  {
    size: 1,
    name: DashboardCardNames.POBox,
  },
  {
    size: 1,
    name: DashboardCardNames.tasks,
  },
  {
    size: 1,
    name: DashboardCardNames.constructionSites,
    configuration: {
      project_id: 1,
    },
  },
  {
    size: 1,
    name: DashboardCardNames.nonContractualWork,
  },
  {
    size: 1,
    name: DashboardCardNames.feedback,
  },
  {
    size: 1,
    name: DashboardCardNames.quickLinks,
  },
  {
    size: 1,
    name: DashboardCardNames.vacations,
  },
];
