import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from '../../../enums/RequestStatus';
import ApiService from '../../../services/ApiService';
import { Dashboard } from './dashboardSlice';

interface DailyPlanningState {
  planning: any | null;
  status: RequestStatus;
  error: string | null;
}

const initialState: DailyPlanningState = {
  planning: null,
  status: RequestStatus.IDLE,
  error: null,
};

// export const getDailyPlanningData = createAsyncThunk('dailyPlanning/dailyPlanning', async () => {
//   const response = await ApiService.get<any>('/api/plannings/daily');
//   return response.data;
// });

export const getDailyPlanningData = createAsyncThunk(
  'dailyPlanning/dailyPlanning',
  async ({ date }: { date: string }) => {
    const response = await ApiService.get<any>('/api/plannings', {
      params: {
        date_start: date,
        date_end: date,
      },
    });

    return response.data;
  }
);

const dailyPlanningSlice = createSlice({
  name: 'dailyPlanning',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDailyPlanningData.pending, state => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(getDailyPlanningData.fulfilled, (state, action: PayloadAction<Dashboard>) => {
        state.status = RequestStatus.SUCCEEDED;
        state.planning = action.payload;
      })
      .addCase(getDailyPlanningData.rejected, (state, action) => {
        state.status = RequestStatus.FAILED;
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default dailyPlanningSlice.reducer;
