import i18n from 'i18next';

export const getDateFormat = () => {
  const locale = i18n.language;

  switch (locale) {
    case 'en':
      return 'DD-MM-YYYY';

    case 'de':
      return 'DD.MM.YYYY';

    default:
      return 'DD.MM.YYYY';
  }
};
